import {
  ADD_NETWORK_ERRORS,
  REMOVE_NETWORK_ERRORS,
  SET_FETCH_NETWORK_STATUS,
  RECEIVE_PIXELS_DATA,
  UPDATE_PIXEL
} from './constants';

const initialState = {
  errors: [],
  pixels: [],
  fetchNetworkStatus: null
};

const pixelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NETWORK_ERRORS: {
      return {
        ...state,
        errors: [...state.errors, action.payload]
      };
    }
    case REMOVE_NETWORK_ERRORS: {
      return {...state, errors: []};
    }
    case SET_FETCH_NETWORK_STATUS: {
      return {
        ...state,
        fetchNetworkStatus: action.payload
      };
    }
    case RECEIVE_PIXELS_DATA: {
      return {
        ...state,
        pixels: action.payload
      };
    }
    case UPDATE_PIXEL: {
      return {
        ...state
      };
    }

    default:
      return state;
  }
};

export default pixelsReducer;
