// Generate google spreadsheet constants
export const CREATE_GOOGLE_SPREADSHET = 'CREATE_GOOGLE_SPREADSHET';
export const GOOGLE_SPREADSHEET_GENERATE_STATUS =
  'GOOGLE_SPREADSHEET_GENERATE_STATUS';
export const CREATE_GOOGLE_SPREADSHEET_ERRORS =
  'CREATE_GOOGLE_SPREADSHEET_ERRORS';

// Store request constants
export const STORE_REQUEST = 'STORE_REQUEST';
export const STORE_REQUEST_STATUS = 'STORE_REQUEST_STATUS';
export const STORE_REQUEST_ERRORS = 'STORE_REQUEST_ERRORS';

// Fetch requests
export const FETCH_REQUESTS = 'FETCH_REQUESTS';
export const FETCH_REQUESTS_STATUS = 'FETCH_REQUESTS_STATUS';
export const FETCH_REQUESTS_ERRORS = 'FETCH_REQUESTS_ERRORS';
