// Retrieve column presets constans
export const GET_COLUMN_PRESETS = 'GET_COLUMN_PRESETS';
export const GET_COLUMN_PRESETS_STATUS = 'GET_COLUMN_PRESETS_STATUS';
export const GET_COLUMN_PRESETS_ERRORS = 'GET_COLUMN_PRESETS_ERRORS';

// Create column preset constants
export const CREATE_COLUMN_PRESET = 'CREATE_COLUMN_PRESET';
export const CREATE_COLUMN_PRESET_STATUS = 'CREATE_COLUMN_PRESET_STATUS';
export const CREATE_COLUMN_PRESET_ERRORS = 'CREATE_COLUMN_PRESET_ERRORS';

// Delete column preset constants
export const DELETE_COLUMN_PRESET = 'DELETE_COLUMN_PRESET';
export const DELETE_COLUMN_PRESET_STATUS = 'DELETE_COLUMN_PRESET_STATUS';
export const DELETE_COLUMN_PRESET_ERRORS = 'DELETE_COLUMN_PRESET_ERRORS';
