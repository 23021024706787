import React from 'react';
import PropTypes from 'prop-types';
import { useNotifications } from '../../context/NotificationContext';
import './Notifications.css';

const Notification = ({ message, onClose }) => (
  <div className="notification">
    <p>{message}</p>
    <button type="button" onClick={onClose}>✖</button> {/* Explicitly setting type="button" */}
  </div>
);

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const Notifications = () => {
  const { notifications, removeNotification } = useNotifications();

  return (
    <div className="notifications-wrapper">
      {notifications.map((notification) => (
        <Notification
          key={notification.id}
          message={notification.message}
          onClose={() => removeNotification(notification.id)}
        />
      ))}
    </div>
  );
};

export default Notifications;
