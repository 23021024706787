import {
  SET_AD_ACCOUNT_ID,
  SET_MEDIA_BUYER_ID,
  SET_NOTIFICATION,
  SET_TOKEN,
} from './constants';

// eslint-disable-next-line import/prefer-default-export
export const setMediaBuyerId = ({ name, permission }) => {
  const parsedData =
    permission === 'admin'
      ? 'admin'
      : name
          .split(' ')
          .map((item) => item[0])
          .join('')
          .toLowerCase();

  return {
    type: SET_MEDIA_BUYER_ID,
    payload: parsedData,
  };
};

export const setToken = (token) => {
  return {
    type: SET_TOKEN,
    payload: token,
  };
};

export const setAdAccountId = (adAccountId) => {
  return {
    type: SET_AD_ACCOUNT_ID,
    payload: adAccountId,
  };
};

export const setNotification = (notification) => {
  return {
    type: SET_NOTIFICATION,
    payload: notification,
  };
};
