import { notification as Notification } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRedux } from '../../lib/hooks';
import { setNotification } from '../../store/app/actions';

const Toaster = () => {

  const dispatch = useDispatch();
  const [notification] = useRedux(['app.notification']);

  useEffect(() => {
    if (notification && notification.message) {
      Notification[notification.type]({
        message: notification.message,
      });
      dispatch(setNotification({ type: null, message: null }));
    }
  }, [notification]);

  return null;
};

export default Toaster;
