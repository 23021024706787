import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { FetchStatus } from '../../constants/enums';

const initialState = {
  errors: [],
  fetchAuthStatus: null,
  addFacebookAccountStatus: null,
  addGoogleAccountStatus: null,
  reauthorizeFacebookStatus: null,
  reauthorizeGoogleStatus: null,
  deleteAccountStatus: null,
  addOutbrainAccountStatus: null,
  authData: {},
  facebookAccountData: {},
  googleAccountData: {},
  outbrainAccountData: {},
  token: null,
};

const baseURL = process.env.REACT_APP_BACKEND_URL;

// Asynchronous Thunks
export const fetchAuthData = createAsyncThunk(
  'auth/fetchAuthData',
  async (payload, { rejectWithValue }) => {
    try {
      const { token, user } = payload;
      const url = `${baseURL}api/auth/login`;
      const params = {
        name: user.name,
        email: user.email,
        image_url: user.picture,
        nickname: user.name,
        sub: user.sub,
      };

      const response = await axios.post(url, params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching auth data:', error);
      return rejectWithValue(error.message);
    }
  },
);

export const addFacebookAccount = createAsyncThunk(
  'auth/addFacebookAccount',
  async (payload) => {
    const { token, user } = payload;
    const url = `${baseURL}api/auth/add_facebook_account`;
    const response = await axios.post(url, {
      params: user,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  },
);

export const addGoogleAccount = createAsyncThunk(
  'auth/addGoogleAccount',
  async (payload) => {
    const { token, code } = payload;
    const url = `${baseURL}api/auth/add_google_account`;
    const response = await axios.post(url, {
      code,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  },
);

export const addOutbrainAccount = createAsyncThunk(
  'auth/addOutbrainAccount',
  async (payload) => {
    const { token, userObj } = payload;
    const url = `${baseURL}api/auth/add_outbrain_account`;
    const response = await axios.post(url, {
      userObj,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  },
);

export const reauthorizeFacebook = createAsyncThunk(
  'auth/reauthorizeFacebook',
  async (payload) => {
    const { token, accountId, user } = payload;
    const url = `${baseURL}api/auth/reauthorize_facebook`;
    const response = await axios.post(url, {
      user,
      accountId,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  },
);

export const reauthorizeGoogle = createAsyncThunk(
  'auth/reauthorizeGoogle',
  async (payload) => {
    const { token, accountId, code } = payload;
    const url = `${baseURL}api/auth/reauthorize_google`;
    const response = await axios.post(url, {
      accountId,
      code,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  },
);

export const deleteAccount = createAsyncThunk(
  'auth/deleteAccount',
  async (payload) => {
    const { token, accountId } = payload;
    const url = `${baseURL}api/auth`;
    await axios.delete(url, {
      data: { accountId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
);

// Slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      // New reducer to set the token
      state.token = action.payload;
    },
    addAuthErrors: (state, action) => {
      state.errors.push(action.payload);
    },
    removeAuthErrors: (state) => {
      state.errors = [];
    },
    setFetchAuthStatus: (state, action) => {
      state.fetchAuthStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuthData.pending, (state) => {
        state.fetchAuthStatus = FetchStatus.PENDING;
      })
      .addCase(fetchAuthData.fulfilled, (state, action) => {
        state.fetchAuthStatus = FetchStatus.SUCCESS;
        state.authData = action.payload;
      })
      .addCase(fetchAuthData.rejected, (state) => {
        state.fetchAuthStatus = FetchStatus.FAILURE;
      })
      .addCase(addFacebookAccount.pending, (state) => {
        state.addFacebookAccountStatus = FetchStatus.PENDING;
      })
      .addCase(addFacebookAccount.fulfilled, (state, action) => {
        state.addFacebookAccountStatus = FetchStatus.SUCCESS;
        state.facebookAccountData = action.payload;
      })
      .addCase(addGoogleAccount.pending, (state) => {
        state.addGoogleAccountStatus = FetchStatus.PENDING;
      })
      .addCase(addGoogleAccount.fulfilled, (state, action) => {
        state.addGoogleAccountStatus = FetchStatus.SUCCESS;
        state.googleAccountData = action.payload;
      })
      .addCase(addOutbrainAccount.pending, (state) => {
        state.addOutbrainAccountStatus = FetchStatus.PENDING;
      })
      .addCase(addOutbrainAccount.fulfilled, (state, action) => {
        state.addOutbrainAccountStatus = FetchStatus.SUCCESS;
        state.outbrainAccountData = action.payload;
      })
      .addCase(reauthorizeFacebook.pending, (state) => {
        state.reauthorizeFacebookStatus = FetchStatus.PENDING;
      })
      .addCase(reauthorizeFacebook.fulfilled, (state, action) => {
        state.reauthorizeFacebookStatus = FetchStatus.SUCCESS;
        state.facebookAccountData = action.payload;
      })
      .addCase(reauthorizeGoogle.pending, (state) => {
        state.reauthorizeGoogleStatus = FetchStatus.PENDING;
      })
      .addCase(reauthorizeGoogle.fulfilled, (state, action) => {
        state.reauthorizeGoogleStatus = FetchStatus.SUCCESS;
        state.googleAccountData = action.payload;
      })
      .addCase(deleteAccount.pending, (state) => {
        state.deleteAccountStatus = FetchStatus.PENDING;
      })
      .addCase(deleteAccount.fulfilled, (state) => {
        state.deleteAccountStatus = FetchStatus.SUCCESS;
      });
  },
});

export const { addAuthErrors, removeAuthErrors, setFetchAuthStatus, setToken } =
  authSlice.actions;

export default authSlice.reducer;
