import {
  ADD_NETWORK_ERRORS,
  REMOVE_NETWORK_ERRORS,
  SET_FETCH_NETWORK_STATUS,
  RECEIVE_AD_ACCOUNTS_DATA,
  UPDATE_AD_ACCOUNT,
  REMOVE_USER_AD_ACCOUNT,
  ASSIGN_USER_ACCOUNT
} from './constants';

const initialState = {
  errors: [],
  adAccounts: [],
  fetchNetworkStatus: null
};

const adAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NETWORK_ERRORS: {
      return {
        ...state,
        errors: [...state.errors, action.payload]
      };
    }
    case REMOVE_NETWORK_ERRORS: {
      return {...state, errors: []};
    }
    case SET_FETCH_NETWORK_STATUS: {
      return {
        ...state,
        fetchNetworkStatus: action.payload
      };
    }
    case RECEIVE_AD_ACCOUNTS_DATA: {
      return {
        ...state,
        adAccounts: action.payload
      };
    }
    case UPDATE_AD_ACCOUNT: {
      return {
        ...state
      };
    }
    case REMOVE_USER_AD_ACCOUNT: {
      return {
        ...state
      }
    }
    case ASSIGN_USER_ACCOUNT: {
      return {
        ...state
      }
    }
    default:
      return state;
  }
};

export default adAccountsReducer;
