import {
  SET_MEDIA_BUYER_ID,
  SET_AD_ACCOUNT_ID,
  SET_NOTIFICATION,
  SET_TOKEN,
} from './constants';

const initialState = {
  mediaBuyerInitials: null,
  adAccountId: null,
  notification: {
    message: null,
    type: null,
  },
  token: null,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MEDIA_BUYER_ID: {
      return {
        ...state,
        mediaBuyerInitials: action.payload,
      };
    }

    case SET_AD_ACCOUNT_ID: {
      return {
        ...state,
        adAccountId: action.payload,
      };
    }

    case SET_NOTIFICATION: {
      return {
        ...state,
        notification: action.payload,
      };
    }

    case SET_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }

    default:
      return state;
  }
};

export default usersReducer;
