import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { setAuthGetterEffluxBE, setAuthGetterLauncherBE } from './api';


export default function AuthInject() {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setAuthGetterEffluxBE(getAccessTokenSilently);
    setAuthGetterLauncherBE(getAccessTokenSilently);
    return () => {setAuthGetterEffluxBE(null);setAuthGetterLauncherBE(null)}; // or undefined, as you originally had
  }, [getAccessTokenSilently]);

  return null;
}

