import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';

// Create a context
const NotificationContext = createContext();

// Define action types
const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

// Notification reducer to handle adding/removing notifications
function notificationReducer(state, action) {
    switch (action.type) {
        case 'ADD_NOTIFICATION':
        console.log('Adding notification:', action.payload); // Log when a notification is added
        return [...state, action.payload];
        case 'REMOVE_NOTIFICATION':
        console.log('Removing notification:', action.payload); // Log when a notification is removed
        return state.filter((notification) => notification.id !== action.payload);
        default:
        return state;
    }
}
  

// Context provider to wrap the app
export const NotificationProvider = ({ children }) => {
  const [notifications, dispatch] = useReducer(notificationReducer, []);

  // Function to add a notification
  const addNotification = (message) => {
    const id = Date.now();
    dispatch({
      type: ADD_NOTIFICATION,
      payload: { id, message },
    });

    // Automatically remove the notification after 3 seconds
    setTimeout(() => {
      dispatch({ type: REMOVE_NOTIFICATION, payload: id });
    }, 3000);
  };

  // Function to remove a notification
  const removeNotification = (id) => {
    dispatch({ type: REMOVE_NOTIFICATION, payload: id });
  };

  return (
    <NotificationContext.Provider value={{ notifications, addNotification, removeNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

// Props validation
NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Custom hook to use the Notification context
export const useNotifications = () => useContext(NotificationContext);
