import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';
import AppReducers from './AppReducers';

const persistConfig = {
  key: 'root',
  storage,
  // Optionally whitelist or blacklist specific parts of the state
};

const persistedReducer = persistReducer(persistConfig, AppReducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV !== 'production', // Optional, for Redux DevTools
});

const persistor = persistStore(store);

export { store, persistor };
