import {
    GET_NETWORK_CAMPAIGNS,
    SET_NETWORK_CAMPAIGNS_STATUS,
    ADD_NETWORK_CAMPAIGN_ERROR
  } from './constants';


const initialState = {
    errors: [],
    networkCampaigns: [],
    networkCampaignsStatus: null,
}

const networkCampaignsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NETWORK_CAMPAIGN_ERROR:{
            return{
                ...state,
                errors: action.payload
            }
        }
        case SET_NETWORK_CAMPAIGNS_STATUS:{
            return {
                ...state,
                networkCampaignsStatus: action.payload
            }
        }
        case GET_NETWORK_CAMPAIGNS:{
            return{
                ...state,
                networkCampaigns: action.payload
            }
        }
        default:
            return state;
    }
}

export default networkCampaignsReducer;