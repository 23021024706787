import {
    GET_COLUMN_PRESETS,
    GET_COLUMN_PRESETS_STATUS,
    GET_COLUMN_PRESETS_ERRORS,
    CREATE_COLUMN_PRESET,
    CREATE_COLUMN_PRESET_STATUS,
    CREATE_COLUMN_PRESET_ERRORS,
    DELETE_COLUMN_PRESET,
    DELETE_COLUMN_PRESET_STATUS,
    DELETE_COLUMN_PRESET_ERRORS
} from './constants';

const initialState = {
    errors: [],
    columnPresets: [],
    columnPresetsStatus: null,
    createColumnPresetStatus: null,
    deleteColumnPresetStatus: null
};

const columnPresetsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COLUMN_PRESETS_ERRORS: {
            return {
                ...state,
                errors: [...state.errors, action.payload]
            };
        }
        case GET_COLUMN_PRESETS_STATUS: {
            return {
                ...state,
                columnPresetsStatus: action.payload
            };
        }
        case GET_COLUMN_PRESETS: {
            return {
                ...state,
                columnPresets: action.payload
            };
        }
        case CREATE_COLUMN_PRESET_ERRORS: {
            return {
                ...state,
                errors: [...state.errors, action.payload]
            };
        }
        case CREATE_COLUMN_PRESET_STATUS: {
            return {
                ...state,
                createColumnPresetStatus: action.payload
            };
        }
        case CREATE_COLUMN_PRESET: {
            return {
                ...state,
                columnPresets: [...state.columnPresets, action.payload]
            };
        }
        case DELETE_COLUMN_PRESET_ERRORS: {
            return {
                ...state,
                errors: [...state.errors, action.payload]
            };
        }
        case DELETE_COLUMN_PRESET_STATUS: {
            return {
                ...state,
                deleteColumnPresetStatus: action.payload
            };
        }
        case DELETE_COLUMN_PRESET: {
            return {
                ...state,
                columnPresets: state.columnPresets.filter(columnPreset => columnPreset.id !== action.payload)
            };
        }
        default:
            return state;
    }
};

export default columnPresetsReducer;