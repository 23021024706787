import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Auth0Provider from './auth';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, persistor } from './store'; // Updated store import
import GlobalStyle from './components/GlobalStyles';
import 'antd/dist/antd.css';
import AuthInject from './api/AuthInject';
import { NotificationProvider } from './context/NotificationContext';

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Auth0Provider>
          <NotificationProvider>
            <GlobalStyle />
            <AuthInject/>
            <App />
          </NotificationProvider>
        </Auth0Provider>
      </PersistGate>
    </Provider>
  </Router>,
  document.getElementById('root'),
);

reportWebVitals();
