import {api} from "../api";

export const fetchUsersWithRelations = async (params) => {
    try {
        const response = await api.get('api/temp/users', { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching ad accounts:', error);
        throw error;
    }
};
