import {
  CREATE_GOOGLE_SPREADSHET,
  GOOGLE_SPREADSHEET_GENERATE_STATUS,
  CREATE_GOOGLE_SPREADSHEET_ERRORS,
  STORE_REQUEST,
  STORE_REQUEST_STATUS,
  STORE_REQUEST_ERRORS,
  FETCH_REQUESTS,
  FETCH_REQUESTS_STATUS,
  FETCH_REQUESTS_ERRORS
} from './constants';

const initialState = {
  errors: [],
  googleSpreadsheetId: null,
  googleSpreadsheetGenerateStatus: null,
  storedRequest: null,
  storedRequestStatus: null,
  requests: [],
  requestsStatus: null
};

const adGeneratorReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_GOOGLE_SPREADSHEET_ERRORS: {
      return {
        ...state,
        errors: [...state.errors, action.payload]
      };
    }

    case GOOGLE_SPREADSHEET_GENERATE_STATUS: {
      return {
        ...state,
        googleSpreadsheetGenerateStatus: action.payload
      };
    }

    case CREATE_GOOGLE_SPREADSHET: {
      return {
        ...state,
        googleSpreadsheetId: action.payload
      };
    }

    case STORE_REQUEST_ERRORS: {
      return {
        ...state,
        errors: [...state.errors, action.payload]
      };
    }

    case STORE_REQUEST_STATUS: {
      return {
        ...state,
        storedRequestStatus: action.payload
      };
    }

    case STORE_REQUEST: {
      return {
        ...state,
        storedRequest: action.payload
      };
    }

    case FETCH_REQUESTS_ERRORS: {
      return {
        ...state,
        errors: [...state.errors, action.payload]
      };
    }

    case FETCH_REQUESTS_STATUS: {
      return {
        ...state,
        requestsStatus: action.payload
      };
    }

    case FETCH_REQUESTS: {
      return {
        ...state,
        requests: action.payload
      };
    }

    default:
      return state;
  }
};

export default adGeneratorReducer;
