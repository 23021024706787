import { combineReducers } from 'redux';
import appReducer from './app/reducer';
import usersReducer from './users/reducer';
import analyticsReducer from './analytics/reducer';
import networkCampaignsReducer from './networkCampaigns/reducer'
import facebookReducer from './facebook/reducer';
import refreshDataReducer from './refreshData/reducer';
import accountManagerReducer from './accountManager/reducer';
import adAccountsReducer from './adAccounts/reducer';
import pixelsReducer from './pixel/reducer';
import adGeneratorReducer from './adGenerator/reducer';
import accountingAndActivityReducer from './accountingAndActivity/reducer';
import columnPresetsReducer from './columnPresets/reducer';
import authSlice from './auth/authSlice';

export default combineReducers({
  app: appReducer,
  auth: authSlice,
  analytics: analyticsReducer,
  networkCampaigns: networkCampaignsReducer,
  facebook: facebookReducer,
  users: usersReducer,
  refresh: refreshDataReducer,
  adAccounts: adAccountsReducer,
  accountManager: accountManagerReducer,
  pixels: pixelsReducer,
  adGenerator: adGeneratorReducer,
  accountingAndActivity: accountingAndActivityReducer,
  columnPresets: columnPresetsReducer,
});
