import axios from 'axios';

// Define the base URLs for the two different axios instances

// Create the first axios instance for `api`
export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Create the second axios instance for `launcherAPI`
export const launcherAPI = axios.create({
  baseURL: process.env.REACT_APP_LAUNCHER_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Create the third axios instance for `cronAPI`
export const cronAPI = axios.create({
  baseURL: process.env.REACT_APP_CRON_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Token getter variables for both instances
let getTokenEffluxBE = null;
let getTokenLauncherBE = null;

// Set the token getter function for the `api` instance
export const setAuthGetterEffluxBE = (tokenGetter) => {
  getTokenEffluxBE = tokenGetter;
};

// Set the token getter function for the `launcherAPI` instance
export const setAuthGetterLauncherBE = (tokenGetter) => {
  getTokenLauncherBE = tokenGetter;
};

// Interceptor logic for the `api` instance
export const interceptEffluxBE = async (config) => {
  if (!getTokenEffluxBE) {
    return config;
  }

  try {
    const token = await getTokenEffluxBE();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (e) {
    console.error('Error getting token for api:', e);
  }
  return config;
};

// Interceptor logic for the `launcherAPI` instance
export const interceptLauncherBE = async (config) => {
  if (!getTokenLauncherBE) {
    return config;
  }

  try {
    const token = await getTokenLauncherBE();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (e) {
    console.error('Error getting token for launcherAPI:', e);
  }
  return config;
};

// Add the interceptors to the axios instances
api.interceptors.request.use(interceptEffluxBE);
launcherAPI.interceptors.request.use(interceptLauncherBE);

// Export both axios instances as default
export default { api, launcherAPI, cronAPI };
