import {
    ADD_NETWORK_ERRORS,
    REMOVE_NETWORK_ERRORS,
    SET_FETCH_NETWORK_STATUS,
    RECEIVE_CROSSROADS_TOTALS_DATA,
    RECEIVE_CROSSROADS_TOTALS_DATA_BY_MEDIA_BUYER,
    RECEIVE_MEDIA_BUYERS_ACTIVITY_REPORT
} from './constants';

const initialState = {
    errors: [],
    fetchNetworkStatus: null,
    crossroadsTotalsData: [],
    crossroadsTotalsDataByMediaBuyers: [],
    mediaBuyersActivityReport: []
};

const accountingAndActivityReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_NETWORK_ERRORS: {
            return {
                ...state,
                errors: [...state.errors, action.payload]
            };
        }
        case REMOVE_NETWORK_ERRORS: {
            return {...state, errors: []};
        }
        case SET_FETCH_NETWORK_STATUS: {
            return {
                ...state,
                fetchNetworkStatus: action.payload
            };
        }
        case RECEIVE_CROSSROADS_TOTALS_DATA: {
            return {
                ...state,
                crossroadsTotalsData: action.payload
            };
        }
        case RECEIVE_CROSSROADS_TOTALS_DATA_BY_MEDIA_BUYER: {
            return {
                ...state,
                crossroadsTotalsDataByMediaBuyers: action.payload
            }
        }
        case RECEIVE_MEDIA_BUYERS_ACTIVITY_REPORT: {
            return {
                ...state,
                mediaBuyersActivityReport: action.payload
            }
        }
        default:
            return state;
    }
};

export default accountingAndActivityReducer;